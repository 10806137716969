import React from "react"

import ErrorContainer from "../components/common/ErrorContainer"
import SEO from "../components/seo.js"

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <ErrorContainer
      title="Not Found"
      description="You just hit a route that doesn't exist... the sadness."
    />
  </>
)

export default NotFoundPage
